var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('my-header',{attrs:{"isSenior":_vm.isSenior},on:{"update:isSenior":function($event){_vm.isSenior=$event},"update:is-senior":function($event){_vm.isSenior=$event}}}),_c('div',{staticClass:"ctn"},[_c('div',[_c('div',{staticClass:"liter_content"},[_c('div',{staticClass:"liter_c_left"},[_c('div',{staticClass:"letf_topbox"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',[_c('div',{staticClass:"toptxt1"},[_vm._v(" "+_vm._s(_vm.bookdetails.title)+" ")]),_c('div',{staticStyle:{"display":"flex","padding-top":"15px","padding-bottom":"15px"}},[(_vm.bookdetails.sourceTitle)?_c('div',{staticClass:"toptxt2"},[_vm._v(" 数据来源："+_vm._s(_vm.bookdetails.sourceTitle)+" 知识类型：析出知识 ")]):_c('div',{staticClass:"toptxt2"},[_vm._v(" 数据来源："+_vm._s(_vm.bookdetails.title)+" 知识类型：文献元数据 ")])])]),_c('div',{staticClass:"goto",on:{"click":_vm.gotoClick}},[_vm._v(" 原图 ")])]),_c('div',{staticClass:"toptxt2"},[_vm._v(" "+_vm._s(_vm.bookdetails.abstract)+" "+_vm._s(_vm.bookdetails.liveInfo)+" ")]),_c('div',{staticClass:"dibuline"})]),_c('div',{staticClass:"letf_secondbox"},[_vm._m(0),_c('div',{staticClass:"indexingbox"},[(
                  this.bookdetails.identifier !== undefined &&
                  this.bookdetails.identifier
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 记录标识号（identifier）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.identifier))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.sourceID !== undefined &&
                  this.bookdetails.sourceID
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 基础资源记录标识号（sourceID）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.sourceID))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.startFileName !== undefined &&
                  this.bookdetails.startFileName
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 起始页文件名（startFileName）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.startFileName))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.endFileName !== undefined &&
                  this.bookdetails.endFileName
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 结束页文件名（endFileName）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.endFileName))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.filePath !== undefined &&
                  this.bookdetails.filePath
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 对象文件路径（filePath）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.filePath))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.type !== undefined && this.bookdetails.type
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 结构类型（type）")]),(_vm.bdetype)?_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bdetype))]):_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.type))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.language !== undefined &&
                  this.bookdetails.language
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 语种（language）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.language))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.title !== undefined &&
                  this.bookdetails.title
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 正题名（title）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.title))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.alternative !== undefined &&
                  this.bookdetails.alternative
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 其他题名（alternative）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.alternative))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.audience !== undefined &&
                  this.bookdetails.audience
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 适用对象（audience）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.audience))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.mediaType !== undefined &&
                  this.bookdetails.mediaType
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 媒体类型（mediaType）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.mediaType))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.spatial !== undefined &&
                  this.bookdetails.spatial
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 空间范围（spatial）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.spatial))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.temporal !== undefined &&
                  this.bookdetails.temporal
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 时间范围（temporal）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.temporal))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.contentForm !== undefined &&
                  this.bookdetails.contentForm
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 内容形式（contentForm）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.contentForm))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.area !== undefined && this.bookdetails.area
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 地区（area）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.area))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.ancestor !== undefined &&
                  this.bookdetails.ancestor
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 始祖（ancestor）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.ancestor))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.dTime !== undefined &&
                  this.bookdetails.dTime
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 时间（dTime）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.dTime))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.diaspora !== undefined &&
                  this.bookdetails.diaspora
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 散居地（diaspora）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.diaspora))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.pages !== undefined &&
                  this.bookdetails.pages
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 页数（pages）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.pages))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.version !== undefined &&
                  this.bookdetails.version
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v(">版本（version）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.version))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.contributor !== undefined &&
                  this.bookdetails.contributor
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 责任者（contributor）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.contributor))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.extent !== undefined &&
                  this.bookdetails.extent
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 页数（extent）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.extent))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.pageNumber !== undefined &&
                  this.bookdetails.pageNumber
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 页码（pageNumber）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.pageNumber))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.classification !== undefined &&
                  this.bookdetails.classification
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 分类号（classification）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.classification))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.keyword !== undefined &&
                  this.bookdetails.keyword
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 关键词（keyword）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.keyword))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.surnameModels !== undefined &&
                  this.bookdetails.surnameModels !== [] &&
                  this.bookdetails.surnameModels !== null &&
                  this.bookdetails.surnameModels.length > 0
                )?_c('div',{staticClass:"indexing2"},[_c('div',[_vm._v("> 姓氏来源（surnameModels）")]),_c('div',{staticClass:"itemlayout"},_vm._l((_vm.bookdetails.surnameModels),function(itemstr,index){return _c('div',{key:index,staticClass:"sentxt2"},[_c('div',[(itemstr.beforeChangingSurname != '')?[_vm._v("改姓前："+_vm._s(itemstr.beforeChangingSurname)+" ")]:_vm._e(),(itemstr.afterChangingSurname != '')?[_vm._v("--- 改姓后："+_vm._s(itemstr.afterChangingSurname))]:_vm._e()],2),(itemstr.sourceOfLastName != '')?_c('div',[_vm._v(" 姓氏来源:"+_vm._s(itemstr.sourceOfLastName)+" ")]):_vm._e()])}),0),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.ancestorModels !== undefined &&
                  this.bookdetails.ancestorModels !== [] &&
                  this.bookdetails.ancestorModels !== null &&
                  this.bookdetails.ancestorModels.length > 0
                )?_c('div',{staticClass:"indexing2"},[_c('div',[_vm._v("> 先祖及重要族人（ancestorModels）")]),_c('div',{staticClass:"itemlayout"},_vm._l((_vm.bookdetails.ancestorModels),function(itemstr,index){return _c('div',{key:index,staticClass:"sentxt2"},[_c('div',[(itemstr.ancestor != '')?[_vm._v("始祖："+_vm._s(itemstr.ancestor))]:_vm._e(),(itemstr.shiQianZu != '')?[_vm._v("--- 始遷祖："+_vm._s(itemstr.shiQianZu))]:_vm._e(),(itemstr.zhiZu != '')?[_vm._v("--- 支祖："+_vm._s(itemstr.zhiZu))]:_vm._e(),(itemstr.fangZu != '')?[_vm._v("--- 房祖："+_vm._s(itemstr.fangZu))]:_vm._e(),(itemstr.nativeCelebrities != '')?[_vm._v("--- 本族名人："+_vm._s(itemstr.nativeCelebrities))]:_vm._e()],2)])}),0),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.anotherName !== undefined &&
                  this.bookdetails.anotherName &&
                  this.bookdetails.anotherName !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 别名（anotherName）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.anotherName))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.sex !== undefined &&
                  this.bookdetails.sex &&
                  this.bookdetails.sex !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 性别（sex）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.sex))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.birthDay !== undefined &&
                  this.bookdetails.birthDay &&
                  this.bookdetails.birthDay !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 出生日期（birthDay）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.birthDay))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.dateOfDeath !== undefined &&
                  this.bookdetails.dateOfDeath &&
                  this.bookdetails.dateOfDeath !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 去世日期（dateOfDeath）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.dateOfDeath))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.period !== undefined &&
                  this.bookdetails.period &&
                  this.bookdetails.period !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 时代（period）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.period))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.notes !== undefined &&
                  this.bookdetails.notes &&
                  this.bookdetails.notes !== null
                )?_c('div',{staticClass:"indexing2"},[_c('div',[_vm._v("> 附注（notes）")]),_c('div',{staticClass:"itemlayout"},[_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.notes))])]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.geographicalNameExtent !== undefined &&
                  this.bookdetails.geographicalNameExtent &&
                  this.bookdetails.geographicalNameExtent !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 地理专名（geographicalName）")]),_c('div',{staticClass:"itemlayout"},[_c('div',{staticClass:"sentxt2"},[_vm._v(" "+_vm._s(_vm.bookdetails.geographicalNameExtent.content)+" ")])]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.abbreviatedGeographicalNameList !==
                    undefined &&
                  this.bookdetails.abbreviatedGeographicalNameList &&
                  this.bookdetails.abbreviatedGeographicalNameList !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 地理简称（abbreviatedGeographicalName）")]),_c('div',{staticClass:"itemlayout"},_vm._l((_vm.bookdetails.abbreviatedGeographicalNameList),function(itmes,index){return _c('div',{key:index,staticClass:"sentxt2"},[_vm._v(" "+_vm._s(itmes.content)+" ")])}),0),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.variantGeographicalNameList !==
                    undefined &&
                  this.bookdetails.variantGeographicalNameList &&
                  this.bookdetails.variantGeographicalNameList !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 异名（variantGeographicalName）")]),_c('div',{staticClass:"itemlayout"},_vm._l((_vm.bookdetails.variantGeographicalNameList),function(itemr,index){return _c('div',{key:index,staticClass:"sentxt2"},[_vm._v(" "+_vm._s(itemr.content)+" ")])}),0),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.administrativeLevel !== undefined &&
                  this.bookdetails.administrativeLevel &&
                  this.bookdetails.administrativeLevel !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 行政层级（AdministrativeLevel）")]),_c('div',{staticClass:"itemlayout"},[_c('div',{staticClass:"sentxt2"},[_vm._v(" "+_vm._s(_vm.bookdetails.administrativeLevel.content)+" ")])]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.startTimeList !== undefined &&
                  this.bookdetails.startTimeList &&
                  this.bookdetails.startTimeList !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 起始年代（startTime）")]),_c('div',{staticClass:"itemlayout"},_vm._l((_vm.bookdetails.startTimeList),function(itemstr,index){return _c('div',{key:index,staticClass:"sentxt2"},[_vm._v(" "+_vm._s(itemstr.content)+" ")])}),0),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.endTimeList !== undefined &&
                  this.bookdetails.endTimeList &&
                  this.bookdetails.endTimeList !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 结束年代（endTime）")]),_c('div',{staticClass:"itemlayout"},_vm._l((_vm.bookdetails.endTimeList),function(itemstr,index){return _c('div',{key:index,staticClass:"sentxt2"},[_vm._v(" "+_vm._s(itemstr.content)+" ")])}),0),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.underJurisdictionList !== undefined &&
                  this.bookdetails.underJurisdictionList &&
                  this.bookdetails.underJurisdictionList !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 隶属（underJurisdiction）")]),_c('div',{staticClass:"itemlayout"},_vm._l((_vm.bookdetails.underJurisdictionList),function(itemstr,index){return _c('div',{key:index,staticClass:"sentxt2"},[_vm._v(" "+_vm._s(itemstr.content)+" ")])}),0),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.jurisdictionList !== undefined &&
                  this.bookdetails.jurisdictionList &&
                  this.bookdetails.jurisdictionList !== null
                )?_c('div',{staticClass:"indexing2"},[_c('div',[_vm._v("> 辖区（Jurisdiction）")]),_vm._l((_vm.bookdetails.jurisdictionList),function(itemstr,index){return _c('div',{key:index,staticClass:"sentxt2"},[_vm._v(" "+_vm._s(itemstr.content)+" ")])}),_c('div',{staticClass:"xlin"})],2):_vm._e(),(
                  this.bookdetails.coordinateList !== undefined &&
                  this.bookdetails.coordinateList &&
                  this.bookdetails.coordinateList !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 经纬度（Coordinate）")]),_vm._l((_vm.bookdetails.coordinateList),function(itemstr,index){return _c('div',{key:index,staticClass:"sentxt2"},[_vm._v(" "+_vm._s(itemstr.content)+" ")])}),_c('div',{staticClass:"xlin"})],2):_vm._e(),(
                  this.bookdetails.azimuthList !== undefined &&
                  this.bookdetails.azimuthList &&
                  this.bookdetails.azimuthList !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 参考方位（Azimuth）")]),_vm._l((_vm.bookdetails.azimuthList),function(itemstr,index){return _c('div',{key:index,staticClass:"sentxt2"},[_vm._v(" "+_vm._s(itemstr.content)+" ")])}),_c('div',{staticClass:"xlin"})],2):_vm._e(),(
                  this.bookdetails.evolutionList !== undefined &&
                  this.bookdetails.evolutionList &&
                  this.bookdetails.evolutionList !== null
                )?_c('div',{staticClass:"indexing2"},[_c('div',[_vm._v("> 沿革事件（evolutionEvent）")]),_c('div',{staticClass:"itemlayout"},_vm._l((_vm.bookdetails.evolutionList.slice(0, 10)),function(itemstr,index){return _c('div',{key:index,staticClass:"sentxt3"},[_c('div',[_vm._v(" 沿革事件类型: "+_vm._s(itemstr.authorityDocumentList.content)+" --- 发生时间："+_vm._s(itemstr.evolutionTime)+" --- 事件说明："+_vm._s(itemstr.notesList.content)+" ")])])}),0),(_vm.iflookmore)?_c('div',{staticClass:"itemlayout"},_vm._l((_vm.bookdetails.evolutionList.slice(10)),function(itemstr,index){return _c('div',{key:index,staticClass:"sentxt3"},[_c('div',[_vm._v(" 沿革事件类型: "+_vm._s(itemstr.authorityDocumentList.content)+" --- 发生时间："+_vm._s(itemstr.evolutionTime)+" --- 事件说明："+_vm._s(itemstr.notesList.content)+" ")])])}),0):_vm._e(),(!_vm.iflookmore)?_c('div',{staticClass:"lookmore",on:{"click":function($event){return _vm.lookmorebtn()}}},[_vm._v(" 点击查看更多 ")]):_vm._e(),(_vm.iflookmore)?_c('div',{staticClass:"lookmore",on:{"click":function($event){return _vm.lookmorebtn()}}},[_vm._v(" 点击收起 ")]):_vm._e(),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.chiOrganizationNameExtent !== undefined &&
                  this.bookdetails.chiOrganizationNameExtent &&
                  this.bookdetails.chiOrganizationNameExtent !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 机构中文名称（ChiOrganizatioNname）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(" "+_vm._s(_vm.bookdetails.chiOrganizationNameExtent.content)+" ")]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.engOrganizationNameExtent !== undefined &&
                  this.bookdetails.engOrganizationNameExtent &&
                  this.bookdetails.engOrganizationNameExtent !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 机构英文名称（engOrganizationNameExtent）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(" "+_vm._s(_vm.bookdetails.engOrganizationNameExtent.content)+" ")]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.abbreviatedOrganizationNameList !==
                    undefined &&
                  this.bookdetails.abbreviatedOrganizationNameList &&
                  this.bookdetails.abbreviatedOrganizationNameList !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 机构简称（AbbreviatedOrganizationName）")]),_vm._l((_vm.bookdetails.abbreviatedOrganizationNameList),function(itemd,index){return _c('div',{key:index,staticClass:"sentxt2"},[_vm._v(" "+_vm._s(itemd.content)+" ")])}),_c('div',{staticClass:"xlin"})],2):_vm._e(),(
                  this.bookdetails.addressList !== undefined &&
                  this.bookdetails.addressList &&
                  this.bookdetails.addressList !== null
                )?_c('div',{staticClass:"indexing2"},[_c('div',[_vm._v("> 地址（Address）")]),_vm._l((_vm.bookdetails.addressList),function(itemd,index){return _c('div',{key:index,staticClass:"sentxt2"},[_vm._v(" "+_vm._s(itemd.content)+" ")])}),_c('div',{staticClass:"xlin"})],2):_vm._e(),(
                  this.bookdetails.previousOrganizationList !== undefined &&
                  this.bookdetails.previousOrganizationList &&
                  this.bookdetails.previousOrganizationList !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 前置机构（previousOrganization）")]),_vm._l((_vm.bookdetails.previousOrganizationList),function(itemd,index){return _c('div',{key:index,staticClass:"sentxt2"},[_vm._v(" "+_vm._s(itemd.content)+" ")])}),_c('div',{staticClass:"xlin"})],2):_vm._e(),(
                  this.bookdetails.nextOrganizationList !== undefined &&
                  this.bookdetails.nextOrganizationList &&
                  this.bookdetails.nextOrganizationList !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 后置机构（nextOrganization）")]),_vm._l((_vm.bookdetails.nextOrganizationList),function(itemd,index){return _c('div',{key:index,staticClass:"sentxt2"},[_vm._v(" "+_vm._s(itemd.content)+" ")])}),_c('div',{staticClass:"xlin"})],2):_vm._e(),(
                  this.bookdetails.personalList !== undefined &&
                  this.bookdetails.personalList &&
                  this.bookdetails.personalList !== null &&
                  this.bookdetails.personalDescriptionList.legend > 0
                )?_c('div',{staticClass:"indexing2"},[_c('div',[_vm._v("> 重要人物名称（PersonalName）")]),_vm._l((_vm.bookdetails.personalList),function(itemd,index){return _c('div',{key:index,staticClass:"sentxt2"},[_vm._v(" "+_vm._s(itemd.content)+" ")])}),_c('div',{staticClass:"xlin"})],2):_vm._e(),(
                  this.bookdetails.personalDescriptionList !== undefined &&
                  this.bookdetails.personalDescriptionList &&
                  this.bookdetails.personalDescriptionList !== null &&
                  this.bookdetails.personalDescriptionList.legend > 0
                )?_c('div',{staticClass:"indexing2"},[_c('div',[_vm._v("> 重要人物事迹（personalDescri ption）")]),_vm._l((_vm.bookdetails.personalDescriptionList),function(itemd,index){return _c('div',{key:index,staticClass:"sentxt2"},[_vm._v(" "+_vm._s(itemd.content)+" ")])}),_c('div',{staticClass:"xlin"})],2):_vm._e(),(
                  this.bookdetails.eventList !== undefined &&
                  this.bookdetails.eventList &&
                  this.bookdetails.eventList !== null
                )?_c('div',{staticClass:"indexing2"},[_c('div',[_vm._v("> 重要事件（event）")]),_vm._l((_vm.bookdetails.eventList),function(itemd,index){return _c('div',{key:index,staticClass:"sentxt2"},[_vm._v(" "+_vm._s(itemd.objectName)+" ")])}),_c('div',{staticClass:"xlin"})],2):_vm._e(),(
                  this.bookdetails.achievementList !== undefined &&
                  this.bookdetails.achievementList &&
                  this.bookdetails.achievementList !== null &&
                  this.bookdetails.achievementList.legend > 0
                )?_c('div',{staticClass:"indexing2"},[_c('div',[_vm._v("> 重要成果（achievement）")]),_vm._l((_vm.bookdetails.achievementList),function(itemd,index){return _c('div',{key:index,staticClass:"sentxt2"},[_vm._v(" "+_vm._s(itemd.content)+" ")])}),_c('div',{staticClass:"xlin"})],2):_vm._e(),(
                  this.bookdetails.chiEventNameExtent !== undefined &&
                  this.bookdetails.chiEventNameExtent &&
                  this.bookdetails.chiEventNameExtent !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 事件中文全称 （chiEventName）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(" "+_vm._s(_vm.bookdetails.chiEventNameExtent.content)+" ")]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.engEventNameExtent !== undefined &&
                  this.bookdetails.engEventNameExtent &&
                  this.bookdetails.engEventNameExtent !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 事件英文全称 （engEventName）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(" "+_vm._s(_vm.bookdetails.engEventNameExtent.content)+" ")]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.abbreviatedEventNameList !== undefined &&
                  this.bookdetails.abbreviatedEventNameList &&
                  this.bookdetails.abbreviatedEventNameList !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 事件简称 （abbreviatedEve ntName）")]),_vm._l((this.bookdetails
                    .abbreviatedEventNameList),function(items,index){return _c('div',{key:index,staticClass:"sentxt2"},[_vm._v(" "+_vm._s(items.content)+" ")])}),_c('div',{staticClass:"xlin"})],2):_vm._e(),(
                  this.bookdetails.placeList !== undefined &&
                  this.bookdetails.placeList &&
                  this.bookdetails.placeList !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 地点 （place）")]),_vm._l((this.bookdetails.placeList),function(items,index){return _c('div',{key:index,staticClass:"sentxt2"},[_vm._v(" "+_vm._s(items.content)+" ")])}),_c('div',{staticClass:"xlin"})],2):_vm._e(),(
                  this.bookdetails.productNameExtent !== undefined &&
                  this.bookdetails.productNameExtent &&
                  this.bookdetails.productNameExtent !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 物产名称（ProductName）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(" "+_vm._s(_vm.bookdetails.productNameExtent.content)+" ")]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.typeList !== undefined &&
                  this.bookdetails.typeList &&
                  this.bookdetails.typeList !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 类型（Type）")]),_vm._l((_vm.bookdetails.typeList),function(itemd,index){return _c('div',{key:index,staticClass:"sentxt2"},[_vm._v(" "+_vm._s(itemd.content)+" ")])}),_c('div',{staticClass:"xlin"})],2):_vm._e(),(
                  this.bookdetails.originPlaceExtent !== undefined &&
                  this.bookdetails.originPlaceExtent &&
                  this.bookdetails.originPlaceExtent !== null
                )?_c('div',{staticClass:"indexing2"},[_c('div',[_vm._v("> 产地（originPlace）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(" "+_vm._s(_vm.bookdetails.originPlaceExtent.objectName)+" ")]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.descriptionList !== undefined &&
                  this.bookdetails.descriptionList &&
                  this.bookdetails.descriptionList !== null
                )?_c('div',{staticClass:"indexing2"},[_c('div',[_vm._v("> 描述（Description）")]),_vm._l((_vm.bookdetails.descriptionList),function(itemd,index){return _c('div',{key:index,staticClass:"sentxt2"},[_vm._v(" "+_vm._s(itemd.content)+" ")])}),_c('div',{staticClass:"xlin"})],2):_vm._e(),(
                  this.bookdetails.yieldList !== undefined &&
                  this.bookdetails.yieldList &&
                  this.bookdetails.yieldList !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 产量（yield）")]),_vm._l((_vm.bookdetails.yieldList),function(itemd,index){return _c('div',{key:index,staticClass:"sentxt2"},[_vm._v(" "+_vm._s(itemd.content)+" ")])}),_c('div',{staticClass:"xlin"})],2):_vm._e(),(
                  this.bookdetails.organizationName !== undefined &&
                  this.bookdetails.organizationName &&
                  this.bookdetails.organizationName !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 机构（organizationName）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.organizationName))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.geographicalName !== undefined &&
                  this.bookdetails.geographicalName &&
                  this.bookdetails.geographicalName !== null
                )?_c('div',{staticClass:"indexing2"},[_c('div',[_vm._v("> 地（geographicalName）")]),_c('div',{staticClass:"itemlayout"},[_c('div',{staticClass:"sentxt2"},[_vm._v(" "+_vm._s(_vm.bookdetails.geographicalName)+" ")])]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.eventName !== undefined &&
                  this.bookdetails.eventName &&
                  this.bookdetails.eventName !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 事（eventName）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.eventName))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.tableNumber !== undefined &&
                  this.bookdetails.tableNumber &&
                  this.bookdetails.tableNumber !== null
                )?_c('div',{staticClass:"indexing"},[_c('div',[_vm._v("> 图表数量（tableNumber）")]),_c('div',{staticClass:"sentxt2"},[_vm._v(_vm._s(_vm.bookdetails.tableNumber))]),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.personalName !== undefined &&
                  this.bookdetails.personalName &&
                  this.bookdetails.personalName !== null
                )?_c('div',{staticClass:"indexing2"},[_c('div',[_vm._v("> 人（personalName）")]),_c('div',{staticClass:"itemlayout"},_vm._l((_vm.bookdetails.personalName
                      .split(';')
                      .slice(0, 20)),function(itemstr,index){return _c('div',{key:index,staticClass:"sentxt3",on:{"click":function($event){return _vm.gonewsearch(itemstr)}}},[_c('div',[_vm._v(" "+_vm._s(itemstr.replace(/\[.*?\]/g, ""))+" ")])])}),0),(_vm.iflookmore)?_c('div',{staticClass:"itemlayout"},_vm._l((_vm.bookdetails.personalName
                      .split(';')
                      .slice(20)),function(itemstr,index){return _c('div',{key:index,staticClass:"sentxt3",on:{"click":function($event){return _vm.gonewsearch(itemstr)}}},[_c('div',[_vm._v(" "+_vm._s(itemstr.replace(/\[.*?\]/g, ""))+" ")])])}),0):_vm._e(),(_vm.bookdetails.personalName.split(';').length > 20)?_c('div',[(!_vm.iflookmore)?_c('div',{staticClass:"lookmore",on:{"click":function($event){return _vm.lookmorebtn()}}},[_vm._v(" 点击查看更多 ")]):_vm._e(),(_vm.iflookmore)?_c('div',{staticClass:"lookmore",on:{"click":function($event){return _vm.lookmorebtn()}}},[_vm._v(" 点击收起 ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.relationships !== undefined &&
                  this.bookdetails.relationships.length > 0 &&
                  this.bookdetails.relationships !== null
                )?_c('div',{staticClass:"indexing2"},[_c('div',[_vm._v("> 亲属关系（relationships）")]),_c('div',{staticClass:"itemlayout"},_vm._l((_vm.bookdetails.relationships.slice(0, 20)),function(itemson1,index){return _c('div',{key:index,staticClass:"sentxt4"},[_vm._v(" "+_vm._s(itemson1.relationshipMemberName)+" -- "+_vm._s(itemson1.relationshipDesc)+" ")])}),0),(_vm.iflookmore)?_c('div',{staticClass:"itemlayout"},_vm._l((_vm.bookdetails.relationships.slice(20)),function(itemstr,index){return _c('div',{key:index,staticClass:"sentxt3",on:{"click":function($event){return _vm.gonewsearch(itemstr)}}},[_c('div',[_vm._v(" "+_vm._s(itemstr.relationshipMemberName)+" -- "+_vm._s(itemstr.relationshipDesc)+" ")])])}),0):_vm._e(),(_vm.bookdetails.relationships.length > 20)?_c('div',[(!_vm.iflookmore)?_c('div',{staticClass:"lookmore",on:{"click":function($event){return _vm.lookmorebtn()}}},[_vm._v(" 点击查看更多 ")]):_vm._e(),(_vm.iflookmore)?_c('div',{staticClass:"lookmore",on:{"click":function($event){return _vm.lookmorebtn()}}},[_vm._v(" 点击收起 ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.nonKinships !== undefined &&
                  this.bookdetails.nonKinships.length > 0 &&
                  this.bookdetails.nonKinships !== null
                )?_c('div',{staticClass:"indexing2"},[_c('div',[_vm._v("> 非亲属关系（nonKinships）")]),_c('div',{staticClass:"itemlayout"},_vm._l((_vm.bookdetails.nonKinships),function(itemson1,index){return _c('div',{key:index,staticClass:"sentxt4"},[_vm._v(" "+_vm._s(itemson1.relationshipDesc)+" -- "+_vm._s(itemson1.relationshipMemberName)+" ")])}),0),_c('div',{staticClass:"xlin"})]):_vm._e(),(
                  this.bookdetails.occupationList !== undefined &&
                  this.bookdetails.occupationList.length > 0 &&
                  this.bookdetails.occupationList !== null
                )?_c('div',{staticClass:"indexing2"},[_c('div',[_vm._v("> 任职记录（occupationList）")]),_c('div',{staticClass:"itemlayout"},_vm._l((_vm.bookdetails.occupationList),function(itemson,index){return _c('div',{key:index,staticClass:"sentxt2"},[(itemson.institution)?_c('span',[_vm._v("任职机构 -- "+_vm._s(itemson.institution)+" -- ")]):_vm._e(),(itemson.position)?_c('span',[_vm._v(" 职位-- "+_vm._s(itemson.position))]):_vm._e(),(itemson.employTime)?_c('span',[_vm._v(" -- 任职时间 -- "+_vm._s(itemson.employTime))]):_vm._e()])}),0),_c('div',{staticClass:"xlin"})]):_vm._e()])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"secondtips"},[_c('div',{staticClass:"sen_left"}),_c('div',{staticClass:"sen_right"},[_vm._v("细颗粒知识标引")])])
}]

export { render, staticRenderFns }