<template>
  <div class="content">
    <my-header :isSenior.sync="isSenior" />
    <div class="ctn">
      <div>
        <div class="liter_content">
          <div class="liter_c_left">
            <div class="letf_topbox">
              <div style="display: flex; justify-content: space-between">
                <div>
                  <div class="toptxt1">
                    {{ bookdetails.title }}
                    <!-- <template> {{ bookdetails.surname }}</template>
                    {{ bookdetails.fullName }} -->
                  </div>
                  <div
                    style="
                      display: flex;
                      padding-top: 15px;
                      padding-bottom: 15px;
                    "
                  >
                    <div class="toptxt2" v-if="bookdetails.sourceTitle">
                      数据来源：{{ bookdetails.sourceTitle }} 知识类型：析出知识
                    </div>
                    <div class="toptxt2" v-else>
                      数据来源：{{ bookdetails.title }} 知识类型：文献元数据
                    </div>
                  </div>
                </div>
                <div class="goto" @click="gotoClick">
                  <!-- {{ bookdetails.eBookForImgGenerated ? "电子书" : "原图" }} -->
                  原图
                </div>
              </div>

              <div class="toptxt2">
                {{ bookdetails.abstract }} {{ bookdetails.liveInfo }}
              </div>
              <div class="dibuline"></div>
            </div>
            <!--  -->
            <div class="letf_secondbox">
              <div class="secondtips">
                <div class="sen_left"></div>
                <div class="sen_right">细颗粒知识标引</div>
              </div>
              <div class="indexingbox">
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.identifier !== undefined &&
                    this.bookdetails.identifier
                  "
                >
                  <div>> 记录标识号（identifier）</div>
                  <div class="sentxt2">{{ bookdetails.identifier }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.sourceID !== undefined &&
                    this.bookdetails.sourceID
                  "
                >
                  <div>> 基础资源记录标识号（sourceID）</div>
                  <div class="sentxt2">{{ bookdetails.sourceID }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.startFileName !== undefined &&
                    this.bookdetails.startFileName
                  "
                >
                  <div>> 起始页文件名（startFileName）</div>
                  <div class="sentxt2">{{ bookdetails.startFileName }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.endFileName !== undefined &&
                    this.bookdetails.endFileName
                  "
                >
                  <div>> 结束页文件名（endFileName）</div>
                  <div class="sentxt2">{{ bookdetails.endFileName }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.filePath !== undefined &&
                    this.bookdetails.filePath
                  "
                >
                  <div>> 对象文件路径（filePath）</div>
                  <div class="sentxt2">{{ bookdetails.filePath }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.type !== undefined && this.bookdetails.type
                  "
                >
                  <div>> 结构类型（type）</div>
                  <div class="sentxt2" v-if="bdetype">{{ bdetype }}</div>
                  <div class="sentxt2" v-else>{{ bookdetails.type }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.language !== undefined &&
                    this.bookdetails.language
                  "
                >
                  <div>> 语种（language）</div>
                  <div class="sentxt2">{{ bookdetails.language }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.title !== undefined &&
                    this.bookdetails.title
                  "
                >
                  <div>> 正题名（title）</div>
                  <div class="sentxt2">{{ bookdetails.title }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.alternative !== undefined &&
                    this.bookdetails.alternative
                  "
                >
                  <div>> 其他题名（alternative）</div>
                  <div class="sentxt2">{{ bookdetails.alternative }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.audience !== undefined &&
                    this.bookdetails.audience
                  "
                >
                  <div>> 适用对象（audience）</div>
                  <div class="sentxt2">{{ bookdetails.audience }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.mediaType !== undefined &&
                    this.bookdetails.mediaType
                  "
                >
                  <div>> 媒体类型（mediaType）</div>
                  <div class="sentxt2">{{ bookdetails.mediaType }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.spatial !== undefined &&
                    this.bookdetails.spatial
                  "
                >
                  <div>> 空间范围（spatial）</div>
                  <div class="sentxt2">{{ bookdetails.spatial }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.temporal !== undefined &&
                    this.bookdetails.temporal
                  "
                >
                  <div>> 时间范围（temporal）</div>
                  <div class="sentxt2">{{ bookdetails.temporal }}</div>
                  <div class="xlin"></div>
                </div>

                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.contentForm !== undefined &&
                    this.bookdetails.contentForm
                  "
                >
                  <div>> 内容形式（contentForm）</div>
                  <div class="sentxt2">{{ bookdetails.contentForm }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.area !== undefined && this.bookdetails.area
                  "
                >
                  <div>> 地区（area）</div>
                  <div class="sentxt2">{{ bookdetails.area }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.ancestor !== undefined &&
                    this.bookdetails.ancestor
                  "
                >
                  <div>> 始祖（ancestor）</div>
                  <div class="sentxt2">{{ bookdetails.ancestor }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.dTime !== undefined &&
                    this.bookdetails.dTime
                  "
                >
                  <div>> 时间（dTime）</div>
                  <div class="sentxt2">{{ bookdetails.dTime }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.diaspora !== undefined &&
                    this.bookdetails.diaspora
                  "
                >
                  <div>> 散居地（diaspora）</div>
                  <div class="sentxt2">{{ bookdetails.diaspora }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.pages !== undefined &&
                    this.bookdetails.pages
                  "
                >
                  <div>> 页数（pages）</div>
                  <div class="sentxt2">{{ bookdetails.pages }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.version !== undefined &&
                    this.bookdetails.version
                  "
                >
                  <div>>版本（version）</div>
                  <div class="sentxt2">{{ bookdetails.version }}</div>
                  <div class="xlin"></div>
                </div>

                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.contributor !== undefined &&
                    this.bookdetails.contributor
                  "
                >
                  <div>> 责任者（contributor）</div>
                  <div class="sentxt2">{{ bookdetails.contributor }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.extent !== undefined &&
                    this.bookdetails.extent
                  "
                >
                  <div>> 页数（extent）</div>
                  <div class="sentxt2">{{ bookdetails.extent }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.pageNumber !== undefined &&
                    this.bookdetails.pageNumber
                  "
                >
                  <div>> 页码（pageNumber）</div>
                  <div class="sentxt2">{{ bookdetails.pageNumber }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.classification !== undefined &&
                    this.bookdetails.classification
                  "
                >
                  <div>> 分类号（classification）</div>
                  <div class="sentxt2">{{ bookdetails.classification }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.keyword !== undefined &&
                    this.bookdetails.keyword
                  "
                >
                  <div>> 关键词（keyword）</div>
                  <div class="sentxt2">{{ bookdetails.keyword }}</div>
                  <div class="xlin"></div>
                </div>
                <!--姓氏来源（surnameModels）开始-->

                <div
                  class="indexing2"
                  v-if="
                    this.bookdetails.surnameModels !== undefined &&
                    this.bookdetails.surnameModels !== [] &&
                    this.bookdetails.surnameModels !== null &&
                    this.bookdetails.surnameModels.length > 0
                  "
                >
                  <div>> 姓氏来源（surnameModels）</div>
                  <div class="itemlayout">
                    <div
                      class="sentxt2"
                      v-for="(itemstr, index) in bookdetails.surnameModels"
                      :key="index"
                    >
                      <div>
                        <template v-if="itemstr.beforeChangingSurname != ''"
                          >改姓前：{{
                            itemstr.beforeChangingSurname
                          }} </template
                        ><template v-if="itemstr.afterChangingSurname != ''"
                          >--- 改姓后：{{
                            itemstr.afterChangingSurname
                          }}</template
                        >
                      </div>
                      <div v-if="itemstr.sourceOfLastName != ''">
                        姓氏来源:{{ itemstr.sourceOfLastName }}
                      </div>
                    </div>
                  </div>
                  <div class="xlin"></div>
                </div>

                <!--姓氏来源（surnameModels）结束-->

                <!--先祖及重要族人（ancestorModels）开始-->

                <div
                  class="indexing2"
                  v-if="
                    this.bookdetails.ancestorModels !== undefined &&
                    this.bookdetails.ancestorModels !== [] &&
                    this.bookdetails.ancestorModels !== null &&
                    this.bookdetails.ancestorModels.length > 0
                  "
                >
                  <div>> 先祖及重要族人（ancestorModels）</div>
                  <div class="itemlayout">
                    <div
                      class="sentxt2"
                      v-for="(itemstr, index) in bookdetails.ancestorModels"
                      :key="index"
                    >
                      <div>
                        <template v-if="itemstr.ancestor != ''"
                          >始祖：{{ itemstr.ancestor }}</template
                        >
                        <template v-if="itemstr.shiQianZu != ''"
                          >--- 始遷祖：{{ itemstr.shiQianZu }}</template
                        >
                        <template v-if="itemstr.zhiZu != ''"
                          >--- 支祖：{{ itemstr.zhiZu }}</template
                        >
                        <template v-if="itemstr.fangZu != ''"
                          >--- 房祖：{{ itemstr.fangZu }}</template
                        >
                        <template v-if="itemstr.nativeCelebrities != ''"
                          >--- 本族名人：{{
                            itemstr.nativeCelebrities
                          }}</template
                        >
                      </div>
                    </div>
                  </div>
                  <div class="xlin"></div>
                </div>
                <!--先祖及重要族人（ancestorModels）结束-->
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.anotherName !== undefined &&
                    this.bookdetails.anotherName &&
                    this.bookdetails.anotherName !== null
                  "
                >
                  <div>> 别名（anotherName）</div>
                  <div class="sentxt2">{{ bookdetails.anotherName }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.sex !== undefined &&
                    this.bookdetails.sex &&
                    this.bookdetails.sex !== null
                  "
                >
                  <div>> 性别（sex）</div>
                  <div class="sentxt2">{{ bookdetails.sex }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.birthDay !== undefined &&
                    this.bookdetails.birthDay &&
                    this.bookdetails.birthDay !== null
                  "
                >
                  <div>> 出生日期（birthDay）</div>
                  <div class="sentxt2">{{ bookdetails.birthDay }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.dateOfDeath !== undefined &&
                    this.bookdetails.dateOfDeath &&
                    this.bookdetails.dateOfDeath !== null
                  "
                >
                  <div>> 去世日期（dateOfDeath）</div>
                  <div class="sentxt2">{{ bookdetails.dateOfDeath }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.period !== undefined &&
                    this.bookdetails.period &&
                    this.bookdetails.period !== null
                  "
                >
                  <div>> 时代（period）</div>
                  <div class="sentxt2">{{ bookdetails.period }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing2"
                  v-if="
                    this.bookdetails.notes !== undefined &&
                    this.bookdetails.notes &&
                    this.bookdetails.notes !== null
                  "
                >
                  <div>> 附注（notes）</div>
                  <div class="itemlayout">
                    <div class="sentxt2">{{ bookdetails.notes }}</div>
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.geographicalNameExtent !== undefined &&
                    this.bookdetails.geographicalNameExtent &&
                    this.bookdetails.geographicalNameExtent !== null
                  "
                >
                  <div>> 地理专名（geographicalName）</div>
                  <div class="itemlayout">
                    <div class="sentxt2">
                      {{ bookdetails.geographicalNameExtent.content }}
                    </div>
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.abbreviatedGeographicalNameList !==
                      undefined &&
                    this.bookdetails.abbreviatedGeographicalNameList &&
                    this.bookdetails.abbreviatedGeographicalNameList !== null
                  "
                >
                  <div>> 地理简称（abbreviatedGeographicalName）</div>
                  <div class="itemlayout">
                    <div
                      class="sentxt2"
                      v-for="(
                        itmes, index
                      ) in bookdetails.abbreviatedGeographicalNameList"
                      :key="index"
                    >
                      {{ itmes.content }}
                    </div>
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.variantGeographicalNameList !==
                      undefined &&
                    this.bookdetails.variantGeographicalNameList &&
                    this.bookdetails.variantGeographicalNameList !== null
                  "
                >
                  <div>> 异名（variantGeographicalName）</div>
                  <div class="itemlayout">
                    <div
                      class="sentxt2"
                      v-for="(
                        itemr, index
                      ) in bookdetails.variantGeographicalNameList"
                      :key="index"
                    >
                      {{ itemr.content }}
                    </div>
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.administrativeLevel !== undefined &&
                    this.bookdetails.administrativeLevel &&
                    this.bookdetails.administrativeLevel !== null
                  "
                >
                  <div>> 行政层级（AdministrativeLevel）</div>
                  <div class="itemlayout">
                    <div class="sentxt2">
                      {{ bookdetails.administrativeLevel.content }}
                    </div>
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.startTimeList !== undefined &&
                    this.bookdetails.startTimeList &&
                    this.bookdetails.startTimeList !== null
                  "
                >
                  <div>> 起始年代（startTime）</div>
                  <div class="itemlayout">
                    <div
                      class="sentxt2"
                      v-for="(itemstr, index) in bookdetails.startTimeList"
                      :key="index"
                    >
                      {{ itemstr.content }}
                    </div>
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.endTimeList !== undefined &&
                    this.bookdetails.endTimeList &&
                    this.bookdetails.endTimeList !== null
                  "
                >
                  <div>> 结束年代（endTime）</div>
                  <div class="itemlayout">
                    <div
                      class="sentxt2"
                      v-for="(itemstr, index) in bookdetails.endTimeList"
                      :key="index"
                    >
                      {{ itemstr.content }}
                    </div>
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.underJurisdictionList !== undefined &&
                    this.bookdetails.underJurisdictionList &&
                    this.bookdetails.underJurisdictionList !== null
                  "
                >
                  <div>> 隶属（underJurisdiction）</div>
                  <div class="itemlayout">
                    <div
                      class="sentxt2"
                      v-for="(
                        itemstr, index
                      ) in bookdetails.underJurisdictionList"
                      :key="index"
                    >
                      {{ itemstr.content }}
                    </div>
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing2"
                  v-if="
                    this.bookdetails.jurisdictionList !== undefined &&
                    this.bookdetails.jurisdictionList &&
                    this.bookdetails.jurisdictionList !== null
                  "
                >
                  <div>> 辖区（Jurisdiction）</div>
                  <div
                    class="sentxt2"
                    v-for="(itemstr, index) in bookdetails.jurisdictionList"
                    :key="index"
                  >
                    {{ itemstr.content }}
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.coordinateList !== undefined &&
                    this.bookdetails.coordinateList &&
                    this.bookdetails.coordinateList !== null
                  "
                >
                  <div>> 经纬度（Coordinate）</div>
                  <div
                    class="sentxt2"
                    v-for="(itemstr, index) in bookdetails.coordinateList"
                    :key="index"
                  >
                    {{ itemstr.content }}
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.azimuthList !== undefined &&
                    this.bookdetails.azimuthList &&
                    this.bookdetails.azimuthList !== null
                  "
                >
                  <div>> 参考方位（Azimuth）</div>
                  <div
                    class="sentxt2"
                    v-for="(itemstr, index) in bookdetails.azimuthList"
                    :key="index"
                  >
                    {{ itemstr.content }}
                  </div>
                  <div class="xlin"></div>
                </div>
                <!-- 111 -->
                <div
                  class="indexing2"
                  v-if="
                    this.bookdetails.evolutionList !== undefined &&
                    this.bookdetails.evolutionList &&
                    this.bookdetails.evolutionList !== null
                  "
                >
                  <div>> 沿革事件（evolutionEvent）</div>
                  <div class="itemlayout">
                    <div
                      class="sentxt3"
                      v-for="(
                        itemstr, index
                      ) in bookdetails.evolutionList.slice(0, 10)"
                      :key="index"
                    >
                      <div>
                        沿革事件类型:
                        {{ itemstr.authorityDocumentList.content }} ---
                        发生时间：{{ itemstr.evolutionTime }} --- 事件说明：{{
                          itemstr.notesList.content
                        }}
                      </div>
                    </div>
                  </div>
                  <div v-if="iflookmore" class="itemlayout">
                    <div
                      class="sentxt3"
                      v-for="(
                        itemstr, index
                      ) in bookdetails.evolutionList.slice(10)"
                      :key="index"
                    >
                      <div>
                        沿革事件类型:
                        {{ itemstr.authorityDocumentList.content }} ---
                        发生时间：{{ itemstr.evolutionTime }} --- 事件说明：{{
                          itemstr.notesList.content
                        }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="lookmore"
                    v-if="!iflookmore"
                    @click="lookmorebtn()"
                  >
                    点击查看更多
                  </div>
                  <div
                    class="lookmore"
                    v-if="iflookmore"
                    @click="lookmorebtn()"
                  >
                    点击收起
                  </div>
                  <div class="xlin"></div>
                </div>

                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.chiOrganizationNameExtent !== undefined &&
                    this.bookdetails.chiOrganizationNameExtent &&
                    this.bookdetails.chiOrganizationNameExtent !== null
                  "
                >
                  <div>> 机构中文名称（ChiOrganizatioNname）</div>
                  <div class="sentxt2">
                    {{ bookdetails.chiOrganizationNameExtent.content }}
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.engOrganizationNameExtent !== undefined &&
                    this.bookdetails.engOrganizationNameExtent &&
                    this.bookdetails.engOrganizationNameExtent !== null
                  "
                >
                  <div>> 机构英文名称（engOrganizationNameExtent）</div>
                  <div class="sentxt2">
                    {{ bookdetails.engOrganizationNameExtent.content }}
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.abbreviatedOrganizationNameList !==
                      undefined &&
                    this.bookdetails.abbreviatedOrganizationNameList &&
                    this.bookdetails.abbreviatedOrganizationNameList !== null
                  "
                >
                  <div>> 机构简称（AbbreviatedOrganizationName）</div>
                  <div
                    class="sentxt2"
                    v-for="(
                      itemd, index
                    ) in bookdetails.abbreviatedOrganizationNameList"
                    :key="index"
                  >
                    {{ itemd.content }}
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing2"
                  v-if="
                    this.bookdetails.addressList !== undefined &&
                    this.bookdetails.addressList &&
                    this.bookdetails.addressList !== null
                  "
                >
                  <div>> 地址（Address）</div>
                  <div
                    class="sentxt2"
                    v-for="(itemd, index) in bookdetails.addressList"
                    :key="index"
                  >
                    {{ itemd.content }}
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.previousOrganizationList !== undefined &&
                    this.bookdetails.previousOrganizationList &&
                    this.bookdetails.previousOrganizationList !== null
                  "
                >
                  <div>> 前置机构（previousOrganization）</div>
                  <div
                    class="sentxt2"
                    v-for="(
                      itemd, index
                    ) in bookdetails.previousOrganizationList"
                    :key="index"
                  >
                    {{ itemd.content }}
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.nextOrganizationList !== undefined &&
                    this.bookdetails.nextOrganizationList &&
                    this.bookdetails.nextOrganizationList !== null
                  "
                >
                  <div>> 后置机构（nextOrganization）</div>
                  <div
                    class="sentxt2"
                    v-for="(itemd, index) in bookdetails.nextOrganizationList"
                    :key="index"
                  >
                    {{ itemd.content }}
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing2"
                  v-if="
                    this.bookdetails.personalList !== undefined &&
                    this.bookdetails.personalList &&
                    this.bookdetails.personalList !== null &&
                    this.bookdetails.personalDescriptionList.legend > 0
                  "
                >
                  <div>> 重要人物名称（PersonalName）</div>
                  <div
                    class="sentxt2"
                    v-for="(itemd, index) in bookdetails.personalList"
                    :key="index"
                  >
                    {{ itemd.content }}
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing2"
                  v-if="
                    this.bookdetails.personalDescriptionList !== undefined &&
                    this.bookdetails.personalDescriptionList &&
                    this.bookdetails.personalDescriptionList !== null &&
                    this.bookdetails.personalDescriptionList.legend > 0
                  "
                >
                  <div>> 重要人物事迹（personalDescri ption）</div>
                  <div
                    class="sentxt2"
                    v-for="(
                      itemd, index
                    ) in bookdetails.personalDescriptionList"
                    :key="index"
                  >
                    {{ itemd.content }}
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing2"
                  v-if="
                    this.bookdetails.eventList !== undefined &&
                    this.bookdetails.eventList &&
                    this.bookdetails.eventList !== null
                  "
                >
                  <div>> 重要事件（event）</div>
                  <div
                    class="sentxt2"
                    v-for="(itemd, index) in bookdetails.eventList"
                    :key="index"
                  >
                    {{ itemd.objectName }}
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing2"
                  v-if="
                    this.bookdetails.achievementList !== undefined &&
                    this.bookdetails.achievementList &&
                    this.bookdetails.achievementList !== null &&
                    this.bookdetails.achievementList.legend > 0
                  "
                >
                  <div>> 重要成果（achievement）</div>
                  <div
                    class="sentxt2"
                    v-for="(itemd, index) in bookdetails.achievementList"
                    :key="index"
                  >
                    {{ itemd.content }}
                  </div>
                  <div class="xlin"></div>
                </div>

                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.chiEventNameExtent !== undefined &&
                    this.bookdetails.chiEventNameExtent &&
                    this.bookdetails.chiEventNameExtent !== null
                  "
                >
                  <div>> 事件中文全称 （chiEventName）</div>
                  <div class="sentxt2">
                    {{ bookdetails.chiEventNameExtent.content }}
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.engEventNameExtent !== undefined &&
                    this.bookdetails.engEventNameExtent &&
                    this.bookdetails.engEventNameExtent !== null
                  "
                >
                  <div>> 事件英文全称 （engEventName）</div>
                  <div class="sentxt2">
                    {{ bookdetails.engEventNameExtent.content }}
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.abbreviatedEventNameList !== undefined &&
                    this.bookdetails.abbreviatedEventNameList &&
                    this.bookdetails.abbreviatedEventNameList !== null
                  "
                >
                  <div>> 事件简称 （abbreviatedEve ntName）</div>
                  <div
                    class="sentxt2"
                    v-for="(items, index) in this.bookdetails
                      .abbreviatedEventNameList"
                    :key="index"
                  >
                    {{ items.content }}
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.placeList !== undefined &&
                    this.bookdetails.placeList &&
                    this.bookdetails.placeList !== null
                  "
                >
                  <div>> 地点 （place）</div>
                  <div
                    class="sentxt2"
                    v-for="(items, index) in this.bookdetails.placeList"
                    :key="index"
                  >
                    {{ items.content }}
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.productNameExtent !== undefined &&
                    this.bookdetails.productNameExtent &&
                    this.bookdetails.productNameExtent !== null
                  "
                >
                  <div>> 物产名称（ProductName）</div>
                  <div class="sentxt2">
                    {{ bookdetails.productNameExtent.content }}
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.typeList !== undefined &&
                    this.bookdetails.typeList &&
                    this.bookdetails.typeList !== null
                  "
                >
                  <div>> 类型（Type）</div>
                  <div
                    class="sentxt2"
                    v-for="(itemd, index) in bookdetails.typeList"
                    :key="index"
                  >
                    {{ itemd.content }}
                  </div>
                  <div class="xlin"></div>
                </div>

                <div
                  class="indexing2"
                  v-if="
                    this.bookdetails.originPlaceExtent !== undefined &&
                    this.bookdetails.originPlaceExtent &&
                    this.bookdetails.originPlaceExtent !== null
                  "
                >
                  <div>> 产地（originPlace）</div>
                  <div class="sentxt2">
                    {{ bookdetails.originPlaceExtent.objectName }}
                  </div>
                  <div class="xlin"></div>
                </div>

                <div
                  class="indexing2"
                  v-if="
                    this.bookdetails.descriptionList !== undefined &&
                    this.bookdetails.descriptionList &&
                    this.bookdetails.descriptionList !== null
                  "
                >
                  <div>> 描述（Description）</div>
                  <div
                    class="sentxt2"
                    v-for="(itemd, index) in bookdetails.descriptionList"
                    :key="index"
                  >
                    {{ itemd.content }}
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.yieldList !== undefined &&
                    this.bookdetails.yieldList &&
                    this.bookdetails.yieldList !== null
                  "
                >
                  <div>> 产量（yield）</div>
                  <div
                    class="sentxt2"
                    v-for="(itemd, index) in bookdetails.yieldList"
                    :key="index"
                  >
                    {{ itemd.content }}
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.organizationName !== undefined &&
                    this.bookdetails.organizationName &&
                    this.bookdetails.organizationName !== null
                  "
                >
                  <div>> 机构（organizationName）</div>
                  <div class="sentxt2">{{ bookdetails.organizationName }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing2"
                  v-if="
                    this.bookdetails.geographicalName !== undefined &&
                    this.bookdetails.geographicalName &&
                    this.bookdetails.geographicalName !== null
                  "
                >
                  <div>> 地（geographicalName）</div>
                  <div class="itemlayout">
                    <div class="sentxt2">
                      {{ bookdetails.geographicalName }}
                    </div>
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.eventName !== undefined &&
                    this.bookdetails.eventName &&
                    this.bookdetails.eventName !== null
                  "
                >
                  <div>> 事（eventName）</div>
                  <div class="sentxt2">{{ bookdetails.eventName }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing"
                  v-if="
                    this.bookdetails.tableNumber !== undefined &&
                    this.bookdetails.tableNumber &&
                    this.bookdetails.tableNumber !== null
                  "
                >
                  <div>> 图表数量（tableNumber）</div>
                  <div class="sentxt2">{{ bookdetails.tableNumber }}</div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing2"
                  v-if="
                    this.bookdetails.personalName !== undefined &&
                    this.bookdetails.personalName &&
                    this.bookdetails.personalName !== null
                  "
                >
                  <div>> 人（personalName）</div>
                  <div class="itemlayout">
                    <div
                      class="sentxt3"
                      v-for="(itemstr, index) in bookdetails.personalName
                        .split(';')
                        .slice(0, 20)"
                      :key="index"
                      @click="gonewsearch(itemstr)"
                    >
                      <div>
                        {{ itemstr.replace(/\[.*&#63;\]/g, "") }}
                      </div>
                    </div>
                  </div>
                  <div v-if="iflookmore" class="itemlayout">
                    <div
                      class="sentxt3"
                      v-for="(itemstr, index) in bookdetails.personalName
                        .split(';')
                        .slice(20)"
                      :key="index"
                      @click="gonewsearch(itemstr)"
                    >
                      <div>
                        {{ itemstr.replace(/\[.*&#63;\]/g, "") }}
                      </div>
                    </div>
                  </div>
                  <div v-if="bookdetails.personalName.split(';').length > 20">
                    <div
                      class="lookmore"
                      v-if="!iflookmore"
                      @click="lookmorebtn()"
                    >
                      点击查看更多
                    </div>
                    <div
                      class="lookmore"
                      v-if="iflookmore"
                      @click="lookmorebtn()"
                    >
                      点击收起
                    </div>
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing2"
                  v-if="
                    this.bookdetails.relationships !== undefined &&
                    this.bookdetails.relationships.length > 0 &&
                    this.bookdetails.relationships !== null
                  "
                >
                  <div>> 亲属关系（relationships）</div>
                  <div class="itemlayout">
                    <div
                      class="sentxt4"
                      v-for="(
                        itemson1, index
                      ) in bookdetails.relationships.slice(0, 20)"
                      :key="index"
                    >
                      {{ itemson1.relationshipMemberName }} --
                      {{ itemson1.relationshipDesc }}
                    </div>
                  </div>
                  <div v-if="iflookmore" class="itemlayout">
                    <div
                      class="sentxt3"
                      v-for="(
                        itemstr, index
                      ) in bookdetails.relationships.slice(20)"
                      :key="index"
                      @click="gonewsearch(itemstr)"
                    >
                      <div>
                        {{ itemstr.relationshipMemberName }} --
                        {{ itemstr.relationshipDesc }}
                      </div>
                    </div>
                  </div>
                  <div v-if="bookdetails.relationships.length > 20">
                    <div
                      class="lookmore"
                      v-if="!iflookmore"
                      @click="lookmorebtn()"
                    >
                      点击查看更多
                    </div>
                    <div
                      class="lookmore"
                      v-if="iflookmore"
                      @click="lookmorebtn()"
                    >
                      点击收起
                    </div>
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing2"
                  v-if="
                    this.bookdetails.nonKinships !== undefined &&
                    this.bookdetails.nonKinships.length > 0 &&
                    this.bookdetails.nonKinships !== null
                  "
                >
                  <div>> 非亲属关系（nonKinships）</div>
                  <div class="itemlayout">
                    <div
                      class="sentxt4"
                      v-for="(itemson1, index) in bookdetails.nonKinships"
                      :key="index"
                    >
                      {{ itemson1.relationshipDesc }} --
                      {{ itemson1.relationshipMemberName }}
                    </div>
                  </div>
                  <div class="xlin"></div>
                </div>
                <div
                  class="indexing2"
                  v-if="
                    this.bookdetails.occupationList !== undefined &&
                    this.bookdetails.occupationList.length > 0 &&
                    this.bookdetails.occupationList !== null
                  "
                >
                  <div>> 任职记录（occupationList）</div>
                  <div class="itemlayout">
                    <div
                      class="sentxt2"
                      v-for="(itemson, index) in bookdetails.occupationList"
                      :key="index"
                    >
                      <span v-if="itemson.institution"
                        >任职机构 -- {{ itemson.institution }} --
                      </span>
                      <span v-if="itemson.position">
                        职位-- {{ itemson.position }}</span
                      >
                      <span v-if="itemson.employTime">
                        -- 任职时间 -- {{ itemson.employTime }}</span
                      >
                    </div>
                  </div>
                  <div class="xlin"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="book">
        <el-image></el-image>
        <div>查看电子书</div>
      </div> -->
    </div>
  </div>
</template>
  
  <script>
import myHeader from "../../components/header";
import { getGenInfo } from "@/api/literature.js";
export default {
  components: {
    myHeader,
  },
  data() {
    return {
      isSenior: false,
      sourceID: "",
      bookdetails: "",
    };
  },
  created() {
    this.sourceID = this.$route.query.sourceID;
    this.openbook();
  },
  methods: {
    gotoClick() {
      // if (this.bookdetails.eBookForImgGenerated) {
      //   let routeData = this.$router.resolve({
      //     path: "/ebook2",
      //     query: {
      //       sourceID: this.bookdetails.sourceID,
      //       bookId: this.bookdetails.id,
      //       Keyword: this.$route.query.searchTitle,
      //     },
      //   });
      //   window.open(routeData.href, "_blank");
      // } else {
      let routeData = this.$router.resolve({
        path: "/ebook",
        query: {
          sourceID: this.bookdetails.sourceID,
          searchTitle: this.$route.query.searchTitle,
        },
      });
      window.open(routeData.href, "_blank");
      // }
    },
    async openbook() {
      const res = await getGenInfo({ sourceID: this.sourceID });
      this.bookdetails = res;
    },
  },
};
</script>
  
  <style scoped lang="scss">
.goto {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #4d6cee;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.content {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  background-color: #f7f7f7;
  /* background: url(~@/assets/image/vztion/bg2.png) center center no-repeat;
    background-size: 100% 100%; */
  /* margin: 0 auto; */
}
.topbgimg {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 280px;
  /* background: url(~@/assets/image/vztion/bg111.png) center center no-repeat; */
  background-size: 100% 100%;
}
.topgif {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 280px;
  /* background: url(~@/assets/image/vztion/topimg4.gif) center center no-repeat; */
  background-size: 100% 100%;
}
.topmenubox {
  position: relative;
  top: 25px;
  width: 750px;
  left: 15%;
}
.menubox {
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
}

.menutxt1 {
  margin-right: 10px;
  font-size: 20px;
  line-height: 20px;
}
.menutxt1:hover {
  color: #7f96f3;
}
.menutxt2 {
  margin-right: 10px;
}
.menutxt2:hover {
  color: #7f96f3;
}
.menutxt3 {
  margin-right: 10px;
}
.menutxt3:hover {
  color: #7f96f3;
}
.ctn {
  width: 1300px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

.search {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.search-input {
  display: flex;
  align-items: center;
  width: 720px;
  height: 68px;
  background-color: #ffffff;
  box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}
.search-button {
  position: absolute;
  right: -85px;
  width: 96px;
  height: 24px;
  border-radius: 12px;
  border: solid 1px #7f96f3;
  color: #7f96f3;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.search-button1 {
  position: absolute;
  top: 21px;
  right: -105px;
  width: 96px;
  height: 24px;
  border-radius: 12px;
  border: solid 1px #7f96f3;
  color: #7f96f3;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.searchbtn {
  width: 100px;
  height: 39px;
  line-height: 39px;
  background-color: #4e6ef2;
  border-radius: 5px;
  /* display: flex;
    align-items: center;
    justify-content: center; */
  margin-right: 20px;
  color: white;
  cursor: pointer;
}
.Search-records {
  margin-top: 39px;
  height: 100px;
  display: flex;
  width: 950px;
}
.Search-title {
  font-size: 20px;
  color: #333333;
  margin-right: 35px;
}
/* 利用穿透，设置input边框隐藏 */
.search-input >>> .el-input__inner {
  border: 0;
}
/* 如果你的 el-input type 设置成textarea ，就要用这个了 */
.search-input >>> .el-textarea__inner {
  border: 0;
  resize: none; /* 这个是去掉 textarea 下面拉伸的那个标志，如下图 */
}
.groupcheck >>> .el-checkbox {
  margin-right: 10px;
}
/* .Search-list{
   
  } */
.groupcheck {
  display: flex;
  justify-content: center;
}
.Search-list-box {
  padding: 5px 15px 5px 15px;
  /* padding-left: 5px;
    padding-right: 5px; */
  border-radius: 17px;
  border: solid 1px #7f96f3;
}
.recommendbox {
  display: flex;
  margin-top: -50px;
  width: 700px;
  flex-wrap: wrap;
}
.recommend {
  height: 25px;
  line-height: 25px;
  color: #7f96f3;
  border: 1px solid #7f96f3;
  text-align: center;
  border-radius: 25px;
  padding: 10px;
  margin-right: 35px;
  margin-bottom: 15px;
}
.answer {
  margin-top: 40px;
  width: 100%;
  height: 400px;
}
.answer-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.answer-smart {
  font-size: 20px;
  color: #4e6ef2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.lookmore {
  font-size: 12px;
  color: #4e6ef2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.answer-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.answer-list-box {
  margin-top: 20px;
  width: 140px;
  height: 164px;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: 20px;
}
.head-img {
  width: 80px;
  height: 80px;
  background-color: #eeeeee;
  border: solid 1px #f4f4f4;
  border-radius: 100%;
}
.head-title {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #333333;
}
.PeopleRetrieval {
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 10px;
  width: 95%;
  height: 164px;
  background-color: #ffffff;
  box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 10px;
}
.active {
  color: #4d6cee;
}
.resultbox {
  display: flex;
}
.resultright {
  margin-left: 50px;
  margin-top: -160px;
  min-width: 800px;
  width: 800px;
}
.resultleft {
  margin-top: -70px;
}
.res_conter {
  width: 900px;
  max-width: 900px;
  background-color: #fff;
  border-radius: 5px;
  text-align: left;
  padding: 25px;
  margin-bottom: 20px;
  cursor: pointer;
}
.res_txt1 {
  font-size: 18px;
}
.res_txt2 {
  font-size: 16px;
  padding-top: 10px;
}

.pageclass {
  text-align: center;
  margin: 30px;
}
.liter_content {
  display: flex;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 30px;
}
.liter_c_left {
  width: 980px;
  background-color: #fff;
  text-align: left;
  padding: 30px;
}
.toptxt1 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}
.toptxt2 {
  font-size: 14px;
}
.lookbtn {
  width: 84px;
  height: 25px;
  color: #fff;
  background-color: #4e6ef2;
  line-height: 25px;
  text-align: center;
  border-radius: 15px;
  font-size: 12px;
  margin-left: 25px;
  cursor: pointer;
}
.dibuline {
  border-bottom: 1px solid #4e6ef2;
  width: 100%;
  margin-top: 15px;
}
.secondtips {
  text-align: left;
  display: flex;
  padding-top: 25px;
}
.sen_left {
  border-right: 5px solid #4e6ef2;
  height: 20px;
  padding-top: 5px;
}
.sen_right {
  font-size: 18px;
  color: #333;
  margin-left: 5px;
  font-weight: bold;
}
.indexingbox {
  display: flex;
  flex-wrap: wrap;
}
.indexing {
  width: 45%;
  padding-top: 25px;
  padding-left: 10px;
  font-size: 13px;
  color: #333;
  margin-right: 35px;
}
.sentxt2 {
  padding-top: 10px;
  padding-left: 15px;
}
.xlin {
  border-bottom: 1px dashed #4e6ef2;
  width: 100%;
  margin-top: 15px;
}
.xlin2 {
  border-bottom: 1px dashed #4e6ef2;
  width: 90%;
  margin-left: 5%;
}
.secondct {
  text-indent: 15px;
  letter-spacing: 1px;
  width: 100%;
}
.pdfbox {
  display: flex;
  margin-top: 25px;
}
.pdf_letf {
  width: 214px;
  height: 604px;
  border: 1px solid #bdc9fb;
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
}
.pdf_letf::-webkit-scrollbar {
  display: none;
}
.pdfname {
  font-size: 16px;
  font-weight: bold;
  padding-top: 15px;
  cursor: pointer;
}
.pdfname:hover {
  color: #4e6ef2;
}
.pdf_right {
  width: 990px;
  height: 604px;
  border: 1px solid #bdc9fb;
  margin-left: 20px;
  position: relative;
  overflow-y: scroll;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.bigbtnbox {
  position: relative;
  right: -20px;
  margin-top: 53%;
  font-size: 24px;
  cursor: pointer;
}
.rightimgbox {
  width: 100%;
  height: 100%;
  top: 0;
  cursor: pointer;
  position: absolute;
}
.imgactive {
  width: 1500px;
  height: 120%;
  top: 0;
  cursor: pointer;
  position: absolute;
}
.rbookimg {
  width: 100%;
  height: 100%;
  position: absolute;
}
.manbanson {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  text-align: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}
.manbanson2 {
  opacity: 0.1;
}
.rightimgbox2 {
  position: fixed;
  z-index: 9;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 60vw;
  height: 77vh;
  margin: 0 auto;
}
.rbookimg2 {
  position: fixed;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  /* width: 930px;
    height: 775px; */
  width: 60vw;
  height: 77vh;
  z-index: 9;
  cursor: pointer;
  background-color: #fff;
}
.frameline2 {
  position: absolute;
  z-index: 99;
  border: 1px solid red;
  width: 25px;
  height: 20px;
}
.frameline {
  position: fixed;
  z-index: 99;
  border: 1px solid red;
  width: 20px;
  height: 20px;
}
.liter_c_right {
  margin-left: 30px;
  width: 260px;
  height: 358px;
}
.goback {
  width: 96px;
  height: 28px;
  color: #fff;
  background-color: #4e6ef2;
  line-height: 28px;
  text-align: center;
  position: relative;
  top: -50px;
  border-radius: 25px;
  font-size: 16px;
}
.c_right_top {
  /* margin-top: -30px; */
  width: 100%;
  height: 45px;
  line-height: 45px;
  text-align: center;
  color: #fff;
  background-color: #4e6ef2;
  margin-bottom: 20px;
}
.c_right_img {
  width: 219px;
  height: 208px;
  border: 1px solid #9daffa;
  margin: 0 auto;
}
.c_right_txt {
  margin: 10px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
}
.c_right_txt:hover {
  color: #4e6ef2;
}
.indexing2 {
  width: 100%;
  padding-top: 25px;
  padding-left: 10px;
  font-size: 13px;
  color: #333;
  margin-right: 35px;
}
.sentxt3 {
  padding-top: 15px;
  padding-left: 15px;
  cursor: pointer;
}
.sentxt4 {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 40px;
}
.itemlayout {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.echartbt2 {
  width: 100%;
  margin-bottom: 60px;
  background-color: #f8f9fa;
  margin-top: 16px;
  border: 1px solid #e5ebff;
}
.schtop {
  width: 100%;
  margin-top: 120px;
}
.topbacg,
.mobilebtn,
.ydtitle {
  display: none;
}
.pctitle {
  display: block;
  margin-left: 10px;
}
.ecanvas {
  height: 600px;
  width: 1000px;
  margin: 0 auto;
}

.lookbtn2,
.yd_btm_content,
.imgname {
  display: none;
}
@media only screen and (max-width: 1440px) {
  .schtop {
    width: 100%;
    margin-top: 50px;
  }
}
@media only screen and (max-width: 450px) {
  .lite_left,
  .groupcheck,
  .menutxt2,
  .menutxt3,
  .menutxt4,
  .liter_c_right,
  .lookbtn,
  .pctitle,
  .btm_content {
    display: none;
  }
  .topbgimg,
  .topgif {
    min-height: 220px;
  }
  .schtop {
    margin-top: 30px;
  }
  .ydtitle {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 40px;
  }
  .topmenubox {
    width: 90%;
    left: 5%;
    top: 15px;
  }
  .mobilebtn {
    color: #fff;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }
  .iconwh {
    width: 18px;
    height: 14px;
  }
  .ydmneubox {
    position: absolute;
    top: 45px;
    z-index: 99;
    width: 100%;
    height: 100px;
    color: #fff;
    text-align: left;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .lookbtn2 {
    display: block;
    position: absolute;
    right: 20px;
    width: 84px;
    height: 25px;
    color: #fff;
    background-color: #4e6ef2;
    line-height: 25px;
    text-align: center;
    border-radius: 15px;
    font-size: 12px;
    margin-left: 25px;
    cursor: pointer;
  }
  .menubox {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .menutxt21,
  .menutxt31 {
    font-size: 18px;
    padding-left: 10px;
    padding-top: 5px;
  }
  .ctn {
    width: 100%;
  }
  .search-input {
    width: 90%;
    height: 48px;
    border-radius: 5px;
  }
  .searchbtn {
    width: 80px;
    height: 29px;
    line-height: 29px;
    border-radius: 5px;
    font-size: 14px;
  }
  .Search-records {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .Search-title {
    width: 33%;
    height: 65px;
    line-height: 10px;
    font-size: 15px;
    color: #fff;
    cursor: pointer;
    padding-right: 0;
    border-right: 1px solid #e7e7ea;
  }
  .liter_c_left {
    width: 95%;
    padding: 0;
    margin-left: 2%;
    margin-top: -40px;
  }
  .toptxtson {
    width: 280px;
  }
  .letf_topbox {
    width: 100%;
  }
  .toplayout {
    flex-wrap: wrap;
  }
  .indexing {
    width: 90%;
  }
  .toptxt1 {
    padding-top: 15px;
    padding-left: 10px;
  }
  .toptxt2 {
    padding-left: 10px;
  }
  .ecanvas {
    width: 300px;
  }
  .lookmore {
    margin-top: 10px;
  }
  .secondtips {
    padding-left: 10px;
  }
  .yd_btm_content {
    display: block;
  }
  .pdf_right {
    width: 100%;
    height: 604px;
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
    overflow-y: scroll;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .rightimgbox {
    margin-left: 2%;
    width: 43%;
    height: 180px;
    position: relative;
  }
  .rbookimg {
    width: 100%;
    height: 90%;
    position: absolute;
  }
  .imgname {
    display: block;
    position: absolute;
    width: 100%;
    height: 10%;
    bottom: 1px;
    font-size: 12px;
    text-align: center;
  }
  .rbookimg2 {
    top: 25%;
    width: 85vw;
    height: 50vh;
  }
  .secondct {
    padding-left: 10px;
    width: 97%;
  }
}
</style>
  